/* 
  author: arul
  about: css themes directly added to the root elements
  notes: please maintain the css variables in all the files
*/
:root {
  --primary-lightest: #e8f7f9;
  --primary-lighter: #a4dee8;
  --primary-light: #60c6d7;
  --primary: #1cadc6;
  --primary-dark: #14798b;
  --primary-darker: #0b454f;
  --primary-darkest: #031114;

  --secondary-lightest: #fff8e8;
  --secondary-lighter: #ffe1a4;
  --secondary-light: #ffcb60;
  --secondary: #ffb51c;
  --secondary-dark: #b37f14;
  --secondary-darker: #66480b;
  --secondary-darkest: #191203;

  --success-lightest: #d1eedd;
  --success-lighter: #a3ddbc;
  --success-light: #5dc389;
  --success: #18a957;
  --success-dark: #11763d;
  --success-darker: #0a4423;
  --success-darkest: #052211;

  --warning-lightest: #fff1d7;
  --warning-lighter: #ffe4af;
  --warning-light: #ffcf74;
  --warning: #ffbb38;
  --warning-dark: #b38327;
  --warning-darker: #664b16;
  --warning-darkest: #33250b;

  --error-lightest: #ffe6eb;
  --error-lighter: #ff99af;
  --error-light: #ff4d74;
  --error: #ff0038;
  --error-dark: #b30027;
  --error-darker: #660016;
  --error-darkest: #190006;

  --bg-shades-lightest: #cecdd0;
  --bg-shades-lighter: #9d9ba1;
  --bg-shades-light: #6b6a73;
  --bg-shades: #3a3844;
  --bg-shades-dark: #211e2c;
  --bg-shades-darker: #161321;
  --bg-shades-darkest: #0e0b1a;

  --gradient-1: #35d7f3;
  --gradient-2: #116b7a;

  /* --grey-text-1: #817e9d; */
  --grey-text-1: #9d9ba1;
  --grey-text-2: #4a485a;

  --dark: #090615;
  --white: #ffffff;
  --black: #000000;
  --transparent: transparent;
  --android-status-bar-style: light-content;
  --ios-status-bar-style: dark-content;
  --backdrop-color: rgba(105, 105, 105, 0.8);
}
