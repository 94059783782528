/* Carousel.css */

.carousel {
  position: relative;
  width: 80%;
  max-width: 600px;
  margin: 40px auto;
  overflow: hidden;
  .carousel__slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .carousel__slide {
    min-width: 100%;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel__slide.active {
    opacity: 1;
    position: relative;
  }

  .carousel__slide img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .carousel__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;
    border-radius: 100%;
    outline: none;
  }

  .carousel__button:hover {
    background-color: transparent;
  }

  .carousel__button--left {
    left: 15px;
  }

  .carousel__button--right {
    right: 15px;
  }

  .carousel__indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
  }

  .carousel__indicator {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
  }

  .carousel__indicator.active {
    width: 30px; /* Longer for the active indicator */
    background-color: #00b4d8; /* Bright color for the active indicator */
    border-radius: 15px; /* More rounded for the active indicator */
  }

  /* New paging style */
  .carousel__paging {
    position: absolute;
    bottom: 10px;
    right: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.875rem;
  }
}
