.overAll {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--bg-shades-light);
  padding: 16px 32px;
  cursor: pointer;
  .imgStyle {
    width: 100%;
    object-fit: contain;
    height: 200px;
  }
}
