body {
  margin: 0;
  /* background-color: var(--dark); */
  /* background-image: url("./images/bg_3.webp"); */
  height: 100%;
  background-size: cover;
  /* background-position: center bottom; */
  background-repeat: no-repeat;
  background-size: 100%;
}

/* @media only screen and (min-width: 1024px) {
  html {
    font-size: 100%;
  }
}
@media only screen and (min-width: 1280px) {
  html {
    font-size: 100%;
  }
}
@media only screen and (min-width: 1536px) {
  html {
    font-size: 115%;
  }
}
@media only screen and (min-width: 1920px) {
  html {
    font-size: 130%;
  }
} */

.particle-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.particle {
  position: absolute;
  width: 50px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  will-change: transform;
  border-radius: 50%;
}
.MuiDrawer-paper::-webkit-scrollbar {
  display: none !important;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../public/assets/fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: url("../public/assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../public/assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "SourceSansPro-Bold";
  src: url("../public/assets/fonts/SourceSansPro-Bold.ttf");
}
@font-face {
  font-family: "SourceSansPro-Regular";
  src: url("../public/assets/fonts/SourceSansPro-Regular.ttf");
}
@font-face {
  font-family: "SourceSansPro-SemiBold";
  src: url("../public/assets/fonts/SourceSansPro-Semibold.ttf");
}

div input textarea {
  outline: none !important;
}

/* input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px var(--color-gray-900) inset;
  -webkit-text-fill-color: var(--color-gray-900);
  -webkit-text-fill-color: #fff;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px var(--color-gray-900) inset;
  -webkit-text-fill-color: var(--color-gray-900);
  -webkit-text-fill-color: #fff;
} */

#toastbar {
  opacity: 0;
  visibility: hidden;
  color: #1a1a1a;
  text-align: center;
  border-radius: 4px;
  padding: 0;
  position: fixed;
  z-index: 99999;
  right: 0px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: fit-content;
  top: 0px;
  margin-left: 16px;
  margin-right: 16px;
  background-color: #ffffff;
  border: 2px solid var(--success);
  border-left: 10px solid var(--success);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
}

#toastbar-text {
  padding-bottom: 8px;
  padding-top: 8px;
  margin-right: 16px;
  font-family: "SourceSansPro-SemiBold";
  font-size: 16px;
}

.show-tost-anim {
  opacity: 1 !important;
  visibility: visible !important;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
}

.type-toast-error {
  border: 1px solid var(--error) !important;
  border-left: 6px solid var(--error) !important;
}

.type-toast-success {
  border: 1px solid var(--success) !important;
  border-left: 6px solid var(--success) !important;
}

.type-toast-warning {
  border: 1px solid var(--warning) !important;
  border-left: 6px solid var(--warning) !important;
}

.MuiInputBase-root {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
  border-radius: 16px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0px;
  height: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: linear-gradient(
    262deg,
    rgba(53, 215, 243, 1),
    rgba(17, 107, 122, 1)
  );
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    262deg,
    rgba(53, 215, 243, 1),
    rgba(17, 107, 122, 1)
  );
}
/* .mdc-checkbox__native-control input[type="checkbox"] {
  accent-color: red !important;
} */
.mdc-label {
  color: #fff !important;
}

.msg-text {
  color: #000 !important;
}
/* Define your breakpoints based on the given sizes */

@media (max-width: 639px) {
}

@media (min-width: 640px) and (max-width: 1023px) {
  /* Styles for small devices (sm) */
}

@media (min-width: 1024px) and (max-width: 1199px) {
  /* Styles for medium devices (md) */
}

@media (min-width: 1200px) and (max-width: 1499px) {
  /* Styles for large devices (lg) */
}

@media (min-width: 1500px) {
  /* Styles for extra large devices (xl) */
}
@media (min-width: 800px) {
  /* Styles for extra large devices (xl) */
  app-uitoolkit {
    max-height: 100vh;
    max-width: 100vw;
    aspect-ratio: auto 4 / 3.5;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  app-previewkit {
    max-height: 100vh;
    max-width: 100vw;
    aspect-ratio: auto 4 / 4;
    align-content: center;
  }

  #sessionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
  }
}
