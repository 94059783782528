.overAll {
  display: flex;
  position: relative;
  margin-top: 1.2rem;
  .leftArrow {
    margin-left: 0.5rem;
    padding: 0;
    margin: 0;
    min-width: 2.25rem;
  }
  .leftArrow {
    margin-right: 0.5rem;
    padding: 0;
    margin: 0;
    min-width: 2.25rem;
  }
  .scroll {
    overflow-x: scroll;
  }
}

/* Scrollbar Track */
.scroll::-webkit-scrollbar {
  height: 0;
}

@media (max-width: 639px) {
  .overAll {
    display: flex;
    position: relative;
    margin-top: 1.2rem;
    .leftArrow {
      display: none;
    }
    .rightArrow {
      display: none;
    }
    .scroll {
      overflow-x: scroll;
      // padding-bottom: 1rem;
    }
    // .scroll::-webkit-scrollbar {
    //   height: 5px !important;
    // }
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
}

@media (min-width: 1024px) and (max-width: 1199px) {
}

@media (min-width: 1200px) and (max-width: 1499px) {
}

@media (min-width: 1500px) {
}
