.overAll {
  .mobileBox {
    display: none;
    .appBar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: var(--dark);
      background-image: none;
    }
  }
  .desktopBox {
    display: flex;
    flex-direction: row;
    .listBox {
      background-color: var(--dark);
      overflow-y: scroll;
    }
    .topNavContainer {
      display: flex;
      flex-direction: column;
      .topNav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: var(--dark);
      }
      .childrenBox {
        border: 1px solid rgba(255, 255, 255, 0.12);
        flex-grow: 1;
        overflow-y: scroll;
      }
    }
  }
}

@media (max-width: 639px) {
  .overAll {
    .mobileBox {
      display: contents;
      .appBar {
      }
    }
    .desktopBox {
      display: none !important;
    }
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
}

@media (min-width: 1024px) and (max-width: 1199px) {
}

@media (min-width: 1200px) and (max-width: 1499px) {
}

@media (min-width: 1500px) {
}

@media (min-width: 1600px) {
  .overAll {
    .desktopBox {
      .topNavContainer {
        .topNav {
        }
        .childrenBox {
          position: relative;
          width: 1320px !important;
        }
      }
    }
  }
}
