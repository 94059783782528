.overAll {
  background-image: url("../../images/bg_3.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 1600px) {
  .overAll {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .content {
      width: 1600px;
    }
  }
}
