.overAll {
  padding: 2rem 8rem;
  display: flex;
  flex-direction: column;
  .btnBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

@media (max-width: 639px) {
  .overAll {
    padding: 1rem;
    iframe {
      width: 100% !important;
    }
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  /* Styles for small devices (sm) */
}

@media (min-width: 1024px) and (max-width: 1199px) {
  /* Styles for medium devices (md) */
}

@media (min-width: 1200px) and (max-width: 1499px) {
  /* Styles for large devices (lg) */
}

@media (min-width: 1500px) {
  /* Styles for extra large devices (xl) */
}
